import { Link } from 'react-router-dom'
import moment from 'moment'

import { Tooltip } from 'components'

import { currencyFormat } from 'utilities'
import routes from 'app/routing/routes'

const useGetColumns = () => {
  const COLUMNS = [
    {
      Header: <p className='m-0 p-0'>Nro.</p>,
      id: 'id',
      accessor: (row) => (
        <Tooltip text='Ir a pedido'>
          <Link to={`${row.order_type_id == 1 ? routes.PURCHASE_REQUEST_DETAIL : routes.INCOME_REQUEST_DETAIL}/${row.id}`}>
            {row.id}
          </Link>
        </Tooltip>
      )
    },
    {
      Header: <p className='m-0 p-0 text-center'>Tipo</p>,
      id: 'order_type_id',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>{row.order_type_label}</p>
      ),
      hasOrder: true
    },
    {
      Header: <p className='m-0 p-0 text-center'>Fecha</p>,
      id: 'date',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {moment(row.created_at).format("DD/MM/YYYY")}
        </p>
      )
    },
    {
      Header: <p className='m-0 p-0'>Proveedor</p>,
      id: 'supplier_name',
      accessor: (row) => (
        <p className='m-0 p-0'>[{row.customer_supplier_id}] {row.supplier_name}</p>
      ),
      hasOrder: true
    },
    {
      Header: <p className='m-0 p-0'>Distribuidor</p>,
      id: 'distributor_name',
      accessor: (row) => (
        <p className='m-0 p-0'>
          {row?.distributor_id ? `[${row.distributor_id}] ${row.distributor_name}` : 'Sin distribuidor'}
        </p>
      ),
      hasOrder:true
    },
    {
      Header: <p className='m-0 p-0 text-end'>Total</p>,
      id: 'total',
      accessor: (row) => (
        <p className='m-0 p-0 text-end'>
          {currencyFormat.format(row?.total || 0)}
        </p>
      ),
      hasOrder: true
    },
    {
      Header: <p className='m-0 p-0 text-center'>Estado</p>,
      id: 'order_status_id',
      accessor: (row) => (
        <div className="badge badge-warning w-100 justify-content-center" style={{ color: "#333333" }}>
          {row.order_status_label}
        </div>
      )
    },
  ]

  return {
    COLUMNS
  }
}

export default useGetColumns
import { useState, useMemo, useEffect } from "react"
import { Link } from "react-router-dom"
import { useTable } from "react-table"
import { Button, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap"
import clsx from 'clsx'

import { useRequest } from "hooks"

import { KTCard, KTCardBody } from "_metronic/helpers"
import { CustomRow } from "./CustomRow"
import Filter from "./Filter"

import useGetColumns from "./columns"
import routes from "app/routing/routes"

const initialObQuery = {
  pageNumber: 1,
  pageSize: 1000
}

const MyTooltip = ({ row }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [data, setData] = useState([])
  const {handleGetRequest} = useRequest()

  const getRequest = async () => {
    if (row) {
      const resp = await handleGetRequest(row.id)

      if (resp && resp.result && resp.result.length > 0) {
        const _data = resp.result[0].detail.map(d => ({
          gtin: d.gtin,
          name: d.product_var_desc,
          qty: d.qty
        }))

        setData(_data)
      }
    }
  }

  useEffect(() => {
    if (showTooltip) {
      getRequest()
    }
  }, [showTooltip])

  return (
    <OverlayTrigger
      placement="left"
      show={showTooltip}
      onToggle={setShowTooltip}
      overlay={
        <Tooltip id="tooltip">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>GTIN</th>
                <th>Nombre</th>
                <th>Cant.</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.gtin}</td>
                  <td>{item.name}</td>
                  <td>{item.qty}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tooltip>
      }
    >
      <span className="me-5">
        <i className='bi bi-info-circle text-success fs-3' />
      </span>
    </OverlayTrigger>
  )
}

export function RequestSelectorModal({ show, onClose }) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState("?order_status_id=2")
  const [queryMD, setQueryMD] = useState(initialObQuery)

  const [sortState, setSortState] = useState({})

  const { COLUMNS } = useGetColumns()
  const [totalCount, setTotalCount] = useState(0)
  const { handleGetRequests } = useRequest()

  const isSelectedForSorting = (id) => {
    return sortState.sort && sortState.sort === id
  }

  const getRequests = async () => {
    try {
      if (queryMD && queryMD.pageNumber) {
        const query = { filter, queryMD }
        const response = await handleGetRequests(query)

        if (response.result) {
          const data = response.result
          setData(data)
          setTotalCount(response.metadata.count)
        }
      }
    } catch (error) {
      setData([])
      console.log(error)
    }
  }

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        <div className='text-center'>
          <MyTooltip row={row} />

          <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>Seleccionar</Tooltip>}>
            <Link to='' className='me-5'>
              <i className="bi bi-clipboard-check text-primary fs-3" style={{ cursor: 'pointer' }} onClick={() => loadRequest(row)} />
            </Link>
          </OverlayTrigger>

          <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>Ir a pedido</Tooltip>}>
            <Link to={`${row.order_type_id == 1 ? routes.PURCHASE_REQUEST_DETAIL : routes.INCOME_REQUEST_DETAIL}/${row.id}`}>
              <i className="bi bi-pencil-square text-primary fs-3" style={{ cursor: 'pointer' }} onClick={() => loadRequest(row)} />
            </Link>
          </OverlayTrigger>
        </div>
      )
    }
  ], [])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  })

  const loadRequest = (row) => {
    onClose(row)
  }

  useEffect(() => {
  }, [data])

  const sortColumn = (id) => {
    if (sortState.order == 'asc') {
      setSortState({ sort: id, order: 'desc' })
      setQueryMD({ ...queryMD, sortField: id, sortOrder: 'desc' })
    } else {
      setSortState({ sort: id, order: 'asc' })
      setQueryMD({ ...queryMD, sortField: id, sortOrder: 'asc' })
    }
  }

  useEffect(() => {
    getRequests()
  }, [handleGetRequests, filter, queryMD])

  return (
    <>
      <Modal size="xl" show={show} backdrop='static' centered>
        <Modal.Header closeButton>
          <Modal.Title>Pedidos pendientes de ingreso</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <KTCard>
            <Filter setFilter={setFilter} queryMD={queryMD} setQueryMD={setQueryMD} />
          </KTCard>

          <KTCard>
            <KTCardBody className='TableContainer'>
              <div>
                <div className='table-responsive' style={{ overflowY: "auto", height: "400px" }}>
                  <table
                    id='kt_table_users'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    {...getTableProps()}
                  >
                    <thead>
                      {headerGroups.map(headerGroup => (
                        <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200" {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => {
                            return (column.hasOrder
                              ?
                                <th {...column.getHeaderProps()}
                                  className={clsx( isSelectedForSorting(column.id) && sortState.order !== undefined && `table-sort-${sortState.order}`)}
                                  style={{cursor: 'pointer'}}
                                  onClick={() => sortColumn(column.id)}
                                >
                                  {column.Header}
                                </th>
                              :
                                <th {...column.getHeaderProps()} >
                                  {column.render('Header')}
                                </th>
                          )})}
                        </tr>
                      ))}
                    </thead>

                    <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                      {rows.length > 0
                        ? (
                          rows.map((row, i) => {
                            prepareRow(row)
                            return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                          })
                        )
                        : (
                          <tr>
                            <td colSpan={7}>
                              <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                No hay registros
                              </div>
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </KTCardBody>
          </KTCard>
        </Modal.Body>

        <Modal.Footer style={{ borderTop: "0 none" }}>
          <Button variant="secondary" type='button' onClick={onClose}>
            <i className="bi bi-slash-circle" />
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
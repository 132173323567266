import React, { useState, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { FormControl, FormCheck, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { helix } from 'ldrs'

import { useCustomerSuppliers } from 'hooks'
import { FormTable } from 'components'
import { PROVINCES } from 'interfaces'

const PerceptionsTab = ({ updating = false }) => {
  const { id } = useParams()
  const { values } = useFormikContext()
  const [update, setUpdate] = useState(false)

  const { loading, handleGetIIBB, handleAddIIBBException, handleUpdateIIBBException } = useCustomerSuppliers()
  const [iibb, setIIBB] = useState([])
  const [exceptions, setExceptions] = useState([])

  // 1. Obtiene las percepciones y excepciones del cliente
  const getIIBB = useCallback(async () => {
    try {
      const response = await handleGetIIBB(id)
      let percepciones = response.data.result.percepciones

      percepciones = percepciones.map(p => ({
        ...p,
        alicuota: p.alicuota * 100,
        exception: {
          ...p?.exception,
          alicuota: p?.exception?.alicuota * 100 || 0,
          enabled: Boolean(p?.exception?.enabled),
          ignorar_regla_negocio: Boolean(p?.exception?.ignorar_regla_negocio),
        },
        enabled: Boolean(p?.exception?.enabled),
      }))

      setIIBB(percepciones.sort((a, b) => a.idProvincia - b.idProvincia))
      setExceptions(percepciones)

      // Reiniciar el estado de excepciones activas
      setExcepcionesActivas(
        percepciones.reduce((acc, p) => ({
          ...acc,
          [p.idProvincia]: Boolean(p?.exception?.enabled),
      }), {})
    );
    } catch (error) {
      setIIBB([])
      setExceptions([])
      setExcepcionesActivas({})
      toast.error(error.message)
    }
  }, [handleGetIIBB, id])

  // 2. Inicializa el estado con los valores iniciales basados en tus datos de provincias
  const [excepcionesActivas, setExcepcionesActivas] = useState(
    iibb.reduce((acc, p) => ({
      ...acc,
      [p.idProvincia]: Boolean(p?.exception?.enabled),
    }), {})
  )

  // 3. Función para manejar el cambio de estado del checkbox
  const handleExcepcionChange = (idProvincia) => {
    setExcepcionesActivas((prevState) => ({
      ...prevState,
      [idProvincia]: !prevState[idProvincia],
    }));

    setExceptions((prevExceptions) => prevExceptions.map((ex) => {
      if (ex.idProvincia === idProvincia) {
        const newEx = {
          ...ex,
          exception: {
            ...ex.exception,
            enabled: !ex?.exception?.enabled,
          }
        }

        // Solo actualiza la alícuota si el checkbox está marcado
        if (!ex?.exception?.enabled) {
          newEx.exception.alicuota = iibb.find((p) => p.idProvincia === idProvincia)?.exception?.alicuota || 0
        }

        return newEx
      }

      return ex
    }));
  }

  // 4. Función para manejar el cambio de la alícuota
  const handleAlicuotaChange = (idProvincia, newAlicuota) => {
    setExceptions((prevExceptions) => prevExceptions.map((ex) => {
      if (ex.idProvincia === idProvincia) {
        return {
          ...ex,
          exception: {
            ...ex.exception,
            alicuota: newAlicuota
          }
        };
      }
      return ex
    }))
  }

  // 5. Función para manejar el cambio del estado del checkbox de "Ignorar regla de negocio"
  const handleIgnorarReglaNegocioChange = (idProvincia) => {
    setExceptions((prevExceptions) => prevExceptions.map((ex) => {
      if (ex.idProvincia === idProvincia) {
        const newEx = {
          ...ex,
          exception: {
            ...ex.exception,
            ignorar_regla_negocio: !ex?.exception?.ignorar_regla_negocio,
          }
        }

        return newEx
      }

      return ex
    }));
  }

  const saveExceptions = async () => {
    try {
      const data = {
        exceptions: [...exceptions],
        documentation_type_id: values.documentation_type_id,
        documentation: values.documentation,
      }
      console.log(data)

      const response = await handleAddIIBBException(id, data)
      toast.success(response.data.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setExceptions([])
      setIIBB([])
      getIIBB()
    }
  }

  useEffect(() => {
    getIIBB()
  }, [getIIBB])

  useEffect(()=>{
    helix.register()
  }, [])

  return (
    <>
      <div className='row mb-8'>
        <div className='col text-end'>
          <Button type='button' variant='primary' onClick={saveExceptions} disabled={loading}>
            <i className='bi bi-save me-2 fs-3' />
            Guardar
          </Button>
        </div>
      </div>

      <FormTable
        columns={[
          { header: 'Provincia' },
          { header: 'Tipo de producto', styles: 'text-center' },
          { header: 'Provincia de entrega', styles: 'text-center' },
          { header: 'Alícuota', styles: 'text-center' },
          { header: 'Tiene excepción', styles: 'text-center' },
          { header: 'Alícuota excepción (%)', styles: 'text-center' },
          { header: 'Ignorar regla de negocio', styles: 'text-center' },
        ]}
      >
        {loading
          ? <tr><td colSpan='7'><l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "5px" }}></l-helix></td></tr>
          : iibb.length <= 0
            ? <tr><td colSpan='7'>El cliente/proveedor no tiene alícuotas definidas.</td></tr>
            : (iibb).map(p => (
              <tr key={`percepcion-${p.idProvincia}`}>
                <td className='align-middle'>{p.provincia}</td>
                <td className='align-middle text-center'>{p.idProvincia == PROVINCES.BUENOS_AIRES ? 'Medicamentos' : 'Todos'}</td>
                <td className='align-middle text-center'>{p.idProvincia == PROVINCES.MISIONES ? 'Misiones' : 'Todos'}</td>
                <td className='align-middle text-center'>{p.alicuota}%</td>
                <td className="align-middle text-center">
                  <FormCheck
                    id={`enabled-excepcion-${p.idProvincia}`}
                    type="checkbox"
                    name={`enabled-excepcion-${p.idProvincia}`}
                    onChange={() => handleExcepcionChange(p.idProvincia)}
                    checked={excepcionesActivas[p.idProvincia]}
                  />
                </td>
                <td className="align-middle text-center">
                  <FormControl
                    id={`alicuota-excepcion-${p.idProvincia}`}
                    name={`alicuota-excepcion-${p.idProvincia}`}
                    type="number"
                    defaultValue={p?.exception?.alicuota || 0}
                    onChange={(e) => handleAlicuotaChange(p.idProvincia, e.target.value)}
                    disabled={!excepcionesActivas[p.idProvincia]}
                  />
                </td>
                <td className="align-middle text-center">
                  <FormCheck
                    id={`ignorar-regla-negocio-excepcion-${p.idProvincia}`}
                    type="checkbox"
                    name={`ignorar-regla-negocio-excepcion-${p.idProvincia}`}
                    onChange={() => handleIgnorarReglaNegocioChange(p.idProvincia)}
                    checked={exceptions.find(ex => ex.idProvincia === p.idProvincia)?.exception?.ignorar_regla_negocio || false}
                    disabled={p.idProvincia !== PROVINCES.MISIONES}
                  />
                </td>
              </tr>
          ))
        }
      </FormTable>
    </>
  )
}

export default PerceptionsTab
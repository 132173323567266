/* eslint-disable react/jsx-no-target-blank */
import { FC } from 'react'
import { useIntl } from 'react-intl'

import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'

import routes from 'app/routing/routes'

const SidebarMenuMain: FC = () => {
const intl = useIntl()

return (
  <>
    <SidebarMenuItem
      to={routes.DASHBOARD}
      icon='element-11'
      title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
      fontIcon='bi-app-indicator'
    />

    {/* VENTAS */}
    <SidebarMenuItemWithSub
      to=''
      title={intl.formatMessage({ id: 'MENU.SALES' })}
      icon='finance-calculator'
      fontIcon='bi-map'
      allowedRoles={["ADMIN", "VENTAS", "VENTAS_GTE", "CONTROL_RENTAS", "CONTROL_CRED", "ATENCION_PACIENTES", "COORDINACION", "COMPRAS", "PRESENTACION"]}
    >

      {/* <SidebarMenuItem to={routes.BUDGETS} title={intl.formatMessage({ id: 'MENU.SALES.BUDGETS' })} hasBullet /> */}
      <SidebarMenuItem
        to={routes.SALES_ORDERS}
        title={intl.formatMessage({ id: 'MENU.SALES.ORDERS' })}
        hasBullet
        allowedRoles={["ADMIN", "VENTAS", "VENTAS_GTE", "CONTROL_RENTAS", "CONTROL_CRED", "ATENCION_PACIENTES", "COORDINACION", "COMPRAS", "PRESENTACION"]}
      />

      <SidebarMenuItemWithSub
        to=''
        title={intl.formatMessage({ id: 'MENU.SALES.AUTHORIZATIONS' })}
        hasBullet
        allowedRoles={["ADMIN", "CONTROL_RENTAS", "CONTROL_CRED"]}
      >

        <SidebarMenuItem
          to={routes.PROFITABILITY_AUTHORIZATIONS}
          title={intl.formatMessage({ id: 'MENU.SALES.AUTHORIZATIONS.PROFITABILITY' })}
          hasBullet
          allowedRoles={["ADMIN", "CONTROL_RENTAS"]}
        />

        <SidebarMenuItem
          to={routes.CREDIT_AUTHORIZATIONS}
          title={intl.formatMessage({ id: 'MENU.SALES.AUTHORIZATIONS.CREDIT' })}
          hasBullet
          allowedRoles={["ADMIN", "CONTROL_CRED", "CONTROL_RENTAS", "CONTROL_CRED"]}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to={routes.LIST_COSTS}
        title={'Costos de lista'}
        hasBullet
        allowedRoles={["ADMIN", "CONTROL_RENTAS", "CONTROL_CRED", "VENTAS", "VENTAS_GTE", "ATENCION_PACIENTES", "COORDINACION", "COMPRAS", "PRESENTACION"]}
      />
    </SidebarMenuItemWithSub>

    {/* COMPRAS */}
    <SidebarMenuItemWithSub
      to=''
      title="Compras"
      icon='purchase'
      fontIcon='bi-map'
      allowedRoles={["ADMIN", "COMPRAS", "CONTROL_COMPRAS", "CONTROL_RENTAS", "COORDINACION", "VENTAS_GTE", "VENTAS", "IMPUESTOS"]}
    >
      <SidebarMenuItem
        to={routes.PURCHASE_REQUESTS}
        title={intl.formatMessage({ id: 'MENU.PURCHASES.ORDERS' })}
        hasBullet
        allowedRoles={[ "ADMIN", "COMPRAS", "CONTROL_RENTAS", "COORDINACION", "IMPUESTOS"]}
      />

      <SidebarMenuItem
        to={routes.AUTHORIZATIONS}
        title={intl.formatMessage({ id: 'MENU.PURCHASES.AUTHORIZATIONS' })}
        hasBullet
        allowedRoles={[ "ADMIN", "CONTROL_COMPRAS", "CONTROL_RENTAS"]}
      />

      <SidebarMenuItem
        to={routes.COSTS}
        title={intl.formatMessage({ id: 'MENU.PURCHASES.COSTS' })}
        hasBullet
        allowedRoles={[ "ADMIN", "COMPRAS", "CONTROL_RENTAS"]}
      />

      <SidebarMenuItem
        to={routes.PURCHASE_STOCK_MANAGEMENT}
        title={"Gestión de compras"}
        hasBullet
        allowedRoles={["ADMIN", "COMPRAS", "CONTROL_RENTAS", "VENTAS_GTE", "VENTAS", "COORDINACION"]}
      />

    </SidebarMenuItemWithSub>

    {/* OPERACIONES */}
    <SidebarMenuItemWithSub
      to=''
      title={intl.formatMessage({ id: 'MENU.LOGISTICS' })}
      icon='truck'
      fontIcon='bi-map'
      allowedRoles={["ADMIN", "OPERACIONES_GTE", "OPERACIONES", "COORDINACION", "ATENCION_PACIENTES", "VENTAS_GTE", "VENTAS"]}
    >
      <SidebarMenuItem
        to={routes.LOGISTIC_INCOME}
        title={intl.formatMessage({ id: 'MENU.LOGISTICS_INCOME' })}
        hasBullet
        allowedRoles={[ "ADMIN", "OPERACIONES_GTE", "OPERACIONES", "COORDINACION"]}
      />

      <SidebarMenuItem
        to={routes.LOGISTICS_ACTIVATION}
        title={intl.formatMessage({ id: 'MENU.LOGISTICS_ACTIVATION' })}
        hasBullet
        allowedRoles={[ "ADMIN", "OPERACIONES_GTE", "OPERACIONES", "COORDINACION"]}
      />

      <SidebarMenuItem
        to={routes.OUT_ORDERS}
        title={intl.formatMessage({ id: 'MENU.LOGSTICS.OUT_ORDERS' })}
        hasBullet
        allowedRoles={[ "ADMIN", "OPERACIONES_GTE", "OPERACIONES", "COORDINACION", "ATENCION_PACIENTES", "VENTAS", "VENTAS_GTE"]}
      />

      <SidebarMenuItem
        to={routes.COORDINATION}
        title={intl.formatMessage({ id: 'MENU.LOGSTICS.COORDINATION' })}
        hasBullet
        allowedRoles={["ADMIN", "OPERACIONES_GTE", "OPERACIONES", "COORDINACION", "ATENCION_PACIENTES", "VENTAS_GTE", "VENTAS"]}
      />

      <SidebarMenuItem
        to={routes.LOGISTICS_REVISION}
        title={"Revisiones"}
        hasBullet
        allowedRoles={[ "ADMIN", "OPERACIONES_GTE", "OPERACIONES", "COORDINACION"]}
      />


      <SidebarMenuItem
        to={routes.WAVES}
        title={intl.formatMessage({ id: 'MENU.LOGISTICS_WAVES' })}
        hasBullet
        allowedRoles={[ "ADMIN", "OPERACIONES_GTE", "OPERACIONES", "COORDINACION"]}
      />

      <SidebarMenuItem
        to={routes.LOGISTICS_DISPATCH_ORDERS}
        title={intl.formatMessage({ id: 'MENU.LOGISTICS_DISPATCHES' })}
        hasBullet
        allowedRoles={[ "ADMIN", "OPERACIONES_GTE", "OPERACIONES", "COORDINACION"]}
      />

      <SidebarMenuItem
        to={routes.LOGISTICS_SHIPMENT_ORDERS}
        title='Órdenes de envío'
        hasBullet
        allowedRoles={[ "ADMIN", "OPERACIONES_GTE", "OPERACIONES", "COORDINACION"]}
      />
    </SidebarMenuItemWithSub>

    {/* FARMACIA */}
    <SidebarMenuItemWithSub
      to=''
      title={intl.formatMessage({ id: 'MENU.PHARMACY' })}
      icon='pulse'
      fontIcon='bi-map'
      allowedRoles={["ADMIN", "FARMACIA"]}
    >
      <SidebarMenuItem
        to=''
        title={intl.formatMessage({ id: 'MENU.PHARMACY_INCOME_RECEPTION' })}
        hasBullet
        allowedRoles={[ "ADMIN", "FARMACIA"]}
      />

      <SidebarMenuItem
        to=''
        title={intl.formatMessage({ id: 'MENU.PHARMACY_EXPENDITURE' })}
        hasBullet
        allowedRoles={[ "ADMIN", "FARMACIA"]}
      />
    </SidebarMenuItemWithSub>

    {/* INVENTARIO */}
    <SidebarMenuItemWithSub
      to='inventario'
      title={intl.formatMessage({ id: 'MENU.INVENTORY' })}
      icon='dropbox'
      fontIcon='bi-map'
      allowedRoles={["ADMIN", "COMPRAS", "OPERACIONES_GTE", "OPERACIONES", "COORDINACION", "CONTROL_RENTAS"]}
    >
      <SidebarMenuItem
        to={routes.ARTICLES}
        title={intl.formatMessage({ id: 'MENU.INVENTORY_ARTICLES' })}
        hasBullet
        allowedRoles={["ADMIN", "COMPRAS", "OPERACIONES_GTE"]}
      />

      <SidebarMenuItem
        to={routes.PRODUCTS}
        title={intl.formatMessage({ id: 'MENU.INVENTORY_PRODUCTS' })}
        hasBullet
        allowedRoles={["ADMIN", "COMPRAS", "OPERACIONES_GTE"]}
      />

      <SidebarMenuItem
        to={routes.WAREHOUSE}
        title={intl.formatMessage({ id: 'MENU.INVENTORY_WAREHOUSES' })}
        hasBullet
        allowedRoles={["ADMIN", "OPERACIONES_GTE", "OPERACIONES", "COORDINACION"]}
      />

      <SidebarMenuItem
        to={routes.INVENTORY_STOCK}
        title={intl.formatMessage({ id: 'MENU.LOGISTICS_STOCK_MANAGEMENT' })}
        hasBullet
        allowedRoles={["ADMIN", "OPERACIONES_GTE", "OPERACIONES", "COORDINACION", "VENTAS_GTE", "VENTAS", "COMPRAS", "CONTROL_RENTAS"]}
      />

      <SidebarMenuItem
        to={routes.INVENTORY_MOVEMENTS}
        title='Movimientos internos de stock'
        hasBullet
        allowedRoles={[ "ADMIN", "OPERACIONES_GTE", "OPERACIONES", "COORDINACION"]}
      />
    </SidebarMenuItemWithSub>

    {/* RELACIONES COMERCIALES */}
    <SidebarMenuItemWithSub
      to=''
      title={intl.formatMessage({ id: 'MENU.COMMERCIAL_RELATIONSHIPS' })}
      icon='profile-user'
      fontIcon='bi-map'
      allowedRoles={["ADMIN", "COMPRAS", "VENTAS", "VENTAS_GTE", "ATENCION_PACIENTES", "IMPUESTOS", "CONTROL_CRED", "OPERACIONES", "OPERACIONES_GTE", "COORDINACION"]}
    >
      <SidebarMenuItem
        to={routes.CUSTOMER_SUPPLIERS}
        title={intl.formatMessage({ id: 'MENU.COMMERCIAL_RELATIONSHIPS_CUSTOMER_SUPPLIERS' })}
        hasBullet
        allowedRoles={["ADMIN", "COMPRAS", "VENTAS", "VENTAS_GTE", "IMPUESTOS", "CONTROL_CRED", "OPERACIONES", "OPERACIONES_GTE", "COORDINACION"]}
      />

      <SidebarMenuItem
        to={routes.PATIENTS}
        title={intl.formatMessage({ id: 'MENU.COMMERCIAL_RELATIONSHIPS_PATIENTS' })}
        hasBullet
        allowedRoles={["ADMIN", "VENTAS", "VENTAS_GTE", "ATENCION_PACIENTES", "COORDINACION", "CONTROL_CRED", "OPERACIONES", "OPERACIONES_GTE"]}
      />

      <SidebarMenuItem
        to={routes.SHIPMENT_ADDRESSES}
        title={intl.formatMessage({ id: 'MENU.COMMERCIAL_RELATIONSHIPS.SHIPMENT_ADDRESSES' })}
        hasBullet
        allowedRoles={["ADMIN", "COMPRAS", "VENTAS", "VENTAS_GTE", "CONTROL_CRED", "OPERACIONES", "OPERACIONES_GTE"]}
      />
    </SidebarMenuItemWithSub>

    {/* MAESTROS */}
    <SidebarMenuItemWithSub
      to=''
      title={intl.formatMessage({ id: 'MENU.MASTERS' })}
      icon='grid'
      fontIcon='bi-map'
      allowedRoles={["ADMIN", "CONTROL_CRED"]}
    >
      <SidebarMenuItem
        to={routes.HEALTH_INSURANCES}
        title={intl.formatMessage({ id: 'MENU.MASTERS_HEALTH_INSURANCES' })}
        hasBullet
        allowedRoles={[ "ADMIN"]}
      />

      <SidebarMenuItem
        to={routes.SELLERS}
        title={intl.formatMessage({ id: 'MENU.MASTERS_SELLERS' })}
        hasBullet
        allowedRoles={[ "ADMIN"]}
      />

      <SidebarMenuItem
        to={routes.CARRIERS}
        title={intl.formatMessage({ id: 'MENU.MASTERS_CARRIERS' })}
        hasBullet
        allowedRoles={[ "ADMIN"]}
      />

      <SidebarMenuItem
        to={routes.PAYMENT_METHODS}
        title={intl.formatMessage({ id: 'MENU.MASTERS.PAYMENT_METHODS' })}
        hasBullet
        allowedRoles={[ "ADMIN"]}
      />

      <SidebarMenuItem
        to={routes.PAYMENT_CONDITIONS}
        title={intl.formatMessage({ id: 'MENU.MASTERS.PAYMENT_CONDITIONS' })}
        hasBullet
        allowedRoles={["ADMIN", "CONTROL_CRED"]}
      />

      <SidebarMenuItem
        to={routes.DOCUMENT_TYPES}
        title={intl.formatMessage({ id: 'MENU.MASTERS.DOCUMENT_TYPES' })}
        hasBullet
        allowedRoles={[ "ADMIN"]}
      />
    </SidebarMenuItemWithSub>

    {/* REPORTES */}
    <SidebarMenuItemWithSub
      to=''
      title={intl.formatMessage({ id: 'MENU.REPORTS' })}
      icon='chart-simple'
      fontIcon='bi-map'
      allowedRoles={[
        "ADMIN", "COMPRAS", "VENTAS", "VENTAS_GTE", "ATENCION_PACIENTES", "IMPUESTOS",
        "CONTROL_RENTAS", "CONTROL_CRED", "OPERACIONES_GTE", "FARMACIA", "CONTROL_COMPRAS",
        "OPERACIONES", "COORDINACION", "PRESENTACION"
      ]}
    >
      <SidebarMenuItem
        to={routes.REPORTE_INGRESOS}
        title='Ingresos'
        hasBullet
        allowedRoles={[
          "ADMIN", "COMPRAS", "VENTAS", "VENTAS_GTE", "ATENCION_PACIENTES", "IMPUESTOS",
          "CONTROL_RENTAS", "CONTROL_CRED", "OPERACIONES_GTE", "FARMACIA", "CONTROL_COMPRAS",
          "OPERACIONES", "COORDINACION", "PRESENTACION"
        ]}
      />
      <SidebarMenuItem
        to={routes.REPORTE_COMPROBANTES}
        title='Comprobantes'
        hasBullet
        allowedRoles={[
          "ADMIN", "COMPRAS", "VENTAS", "VENTAS_GTE", "ATENCION_PACIENTES", "IMPUESTOS",
          "CONTROL_RENTAS", "CONTROL_CRED", "OPERACIONES_GTE", "FARMACIA", "CONTROL_COMPRAS",
          "OPERACIONES", "COORDINACION", "PRESENTACION"
        ]}
      />

      <SidebarMenuItem
        to={routes.REPORTE_VENTAS}
        title='Ventas'
        hasBullet
        allowedRoles={[
          "ADMIN", "COMPRAS", "VENTAS", "VENTAS_GTE", "ATENCION_PACIENTES", "IMPUESTOS",
          "CONTROL_RENTAS", "CONTROL_CRED", "OPERACIONES_GTE", "FARMACIA", "CONTROL_COMPRAS",
          "OPERACIONES", "COORDINACION", "PRESENTACION"
        ]}
      />

      <SidebarMenuItem
        to={routes.REPORTE_REMITOS}
        title='Remitos'
        hasBullet
        allowedRoles={[
          "ADMIN", "COMPRAS", "VENTAS", "VENTAS_GTE", "ATENCION_PACIENTES", "IMPUESTOS",
          "CONTROL_RENTAS", "CONTROL_CRED", "OPERACIONES_GTE", "FARMACIA", "CONTROL_COMPRAS",
          "OPERACIONES", "COORDINACION", "PRESENTACION"
        ]}
      />

      <SidebarMenuItem
        to={routes.REPORTE_INVENTARIO}
        title={intl.formatMessage({ id: 'MENU.REPORTS_INVENTORY' })}
        hasBullet
        allowedRoles={[
          "ADMIN", "COMPRAS", "VENTAS", "VENTAS_GTE", "ATENCION_PACIENTES", "IMPUESTOS",
          "CONTROL_RENTAS", "CONTROL_CRED", "OPERACIONES_GTE", "FARMACIA", "CONTROL_COMPRAS",
          "OPERACIONES", "COORDINACION", "PRESENTACION"
        ]}
      />

      <SidebarMenuItem
        to={routes.REPORTE_ANALISIS_STOCK}
        title={'Ped. ventas - Análisis de stock'}
        hasBullet
        allowedRoles={[
          "ADMIN", "COMPRAS", "VENTAS", "VENTAS_GTE", "ATENCION_PACIENTES", "IMPUESTOS",
          "CONTROL_RENTAS", "CONTROL_CRED", "OPERACIONES_GTE", "FARMACIA", "CONTROL_COMPRAS",
          "OPERACIONES", "COORDINACION", "PRESENTACION"
        ]}
      />

      {/* <SidebarMenuItem
        to=''
        title={intl.formatMessage({ id: 'MENU.REPORTS_PURCHASES' })}
        hasBullet
        allowedRoles={["ADMIN", "CONTROL_RENTAS", "IMPUESTOS"]}
      />

      <SidebarMenuItem
        to=''
        title={intl.formatMessage({ id: 'MENU.REPORTS_VERIFARMA' })}
        hasBullet
        allowedRoles={["ADMIN", "CONTROL_RENTAS", "IMPUESTOS"]}
      />

      <SidebarMenuItem
        to=''
        title={intl.formatMessage({ id: 'MENU.REPORTS_MEDIFE' })}
        hasBullet
        allowedRoles={["ADMIN", "CONTROL_RENTAS", "IMPUESTOS"]}
      /> */}
    </SidebarMenuItemWithSub>

    {/* INTEGRACION */}
    <SidebarMenuItemWithSub
      to=''
      title="Integraciones"
      icon='messages'
      fontIcon='bi-map'
      allowedRoles={["ADMIN"]}
    >
      <SidebarMenuItem
        to=''
        title={intl.formatMessage({ id: 'MENU.INTEGRATION_LOG_AFIP' })}
        hasBullet
        allowedRoles={[ "ADMIN"]}
      />

      <SidebarMenuItem
        to={routes.INTEGRATION_ANMAT_ERRORS}
        title={intl.formatMessage({ id: 'MENU.INTEGRATION_LOG_TRACEABILITY' })}
        hasBullet
        allowedRoles={[ "ADMIN"]}
      />

      <SidebarMenuItem
        to={routes.INTEGRATION_VERIFARMA}
        title="Envíos Verifarma"
        hasBullet
        allowedRoles={[ "ADMIN"]}
      />
    </SidebarMenuItemWithSub>

    {/* ADMINISTRACION */}
    <SidebarMenuItemWithSub
      to=''
      title={intl.formatMessage({ id: 'MENU.ADMINISTRATIVE' })}
      icon='bank'
      fontIcon='bi-bank'
      allowedRoles={["ADMIN", "OPERACIONES_GTE", "OPERACIONES", "COORDINACION", "CONTROL_RENTAS", "CONTROL_CRED"]}
    >
      <SidebarMenuItem
        to={routes.ADMINISTRATIVE_VOUCHERS}
        title={intl.formatMessage({ id: 'MENU.ADMINISTRATIVE_VOUCHERS' })}
        hasBullet
        allowedRoles={["ADMIN", "OPERACIONES_GTE", "OPERACIONES", "COORDINACION", "CONTROL_RENTAS", "CONTROL_CRED"]}
      />

      <SidebarMenuItem
        to={routes.ADMINISTRATIVE_PENDING_INVOICES}
        title="Pendientes de facturación"
        hasBullet
        allowedRoles={["ADMIN", "CONTROL_CRED"]}
      />

      {/* <SidebarMenuItem
        to={routes.ADMINISTRATIVE_GROUPED_INVOICES}
        title="Facturas agrupadas"
        hasBullet
        allowedRoles={["ADMIN"]}
      /> */}

    </SidebarMenuItemWithSub>
  </>
)
}

export {SidebarMenuMain}
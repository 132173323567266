import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip as TooltipBS } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import moment from 'moment'

import { useAuth } from 'app/modules/auth'

import { Tooltip } from 'components'
import { OutOrderStatusI } from 'interfaces'
import { currencyFormat } from 'utilities'

import routes from 'app/routing/routes'

import { RoleI } from 'interfaces'

export const useGetColumns = (selectedRows, handleRowSelection) => {
  const { currentUser } = useAuth()

  const intl = useIntl()

  const COLUMNS = [
    {
      Header: '',
      id: 'selected',
      accessor: (row) => (
        currentUser?.roles.some(r => r.id == RoleI.ADMIN || r.id == RoleI.OPERACIONES || r.id == RoleI.OPERACIONES_GTE) &&
        row.out_order_status_id === OutOrderStatusI.PENDIENTE_PREPARACION
          ?
            <input
              name="selected"
              type="checkbox"
              value={row.id}
              onChange={() => handleRowSelection(row.id, row.out_order_status_id)}
              checked={selectedRows.some(i => i.id === row.id)}
              disabled={!currentUser?.roles.some(r => r.id == RoleI.ADMIN || r.id == RoleI.OPERACIONES || r.id == RoleI.OPERACIONES_GTE)}
            />
          : <></>
      )
    },
    {
      Header: intl.formatMessage({ id: 'COLUMN.OUT_ORDERS.ID' }),
      id: 'id',
      accessor: (row) => (
        <Link to={`${routes.OUT_ORDERS}/${row.id}`}>
          {row.id}
        </Link>
      ),
      hasOrder: true,
    },
    {
      Header: <div className='text-center'>F. creación</div>,
      id: 'created_at',
      accessor: (row) => (
        <p className='text-center m-0'>
          {moment(row.created_at).format('DD/MM/YYYY')}
        </p>
      ),
      hasOrder: true,
    },
    {
      Header: <div className='text-center'>ID Pedido</div>,
      id: 'sales_order_id',
      accessor: (row) => (
        <div className='text-center'>
          <Link to={`${routes.SALES_ORDERS}/${row.sales_order_id}`}>
            {row.sales_order_id}
          </Link>
        </div>
      ),
      hasOrder: true,
    },
    {
      Header: <div className='text-center'>F. entrega</div>,
      id: 'dispatch_date',
      accessor: (row) => (
        <p className='text-center m-0'>
          {row.dispatch_date ? moment(row.dispatch_date).format('DD/MM/YYYY') : '-'}
        </p>
      ),
      hasOrder: true,
    },
    {
      Header: intl.formatMessage({ id: 'COLUMN.OUT_ORDERS.CLIENT' }),
      id: 'customer_supplier_id',
      accessor: (row) => (
        <>
          <strong className='m-0 p-0'>[{row.customer_supplier_id}] {row.customer_supplier_business_name}</strong>
          <p className='m-0 p-0 mt-2'>{row.customer_supplier_documentation_type}: {row. customer_supplier_documentation}</p>

          {row.patient_id &&
            <div className='mt-4'>
              <p className='m-0 p-0'>Paciente: <strong className='m-0 p-0'>[{row.patient_id}] {row.patient_name}</strong></p>
              <p className='m-0 p-0 mt-2'>Documento: {row?.patient_documentation || '-'}</p>
              <p className='m-0 p-0 mt-2'>Nro. afiliado: {row?.patient_affiliate_number || '-'}</p>
            </div>
          }
        </>
      ),
      // hasOrder: true,
    },
    {
      Header: 'Nombre / Domicilio',
      id: 'address_id_ship',
      accessor: (row) => (
        <>
          <strong className='m-0 p-0'>{row.ship_name}</strong>
          <p className='m-0 p-0 mt-4'>{row?.ship_address?.trim()}</p>
          <p className='m-0 p-0 mt-2'>GLN: {row?.ship_gln || '-'}</p>
        </>
      )
    },
    {
      Header: 'Localidad',
      id: 'ship_full_locality',
      accessor: (row) => (
        <p className='m-0 p-0'>{row?.ship_full_locality?.trim()}</p>
      )
    },
    {
      Header: 'Provincia',
      id: 'ship_provincia',
      accessor: (row) => (
        <p className='m-0 p-0'>{row?.ship_provincia}</p>
      )
    },
    {
      Header: <div className='text-center m-0'>Artículos</div>,
      id: 'lines',
      accessor: (row) => (
        <div className='text-center'>
          {row.lines === 0 ? '-' : row.lines}

          {row?.detail &&
            <OverlayTrigger
              placement='top'
              overlay={
                <TooltipBS id={`tooltip-${row.id}`}>
                  <div>
                    {row.detail.split(',').map((item, index) => (
                      <div key={index}>{item.trim()}</div>
                    ))}
                  </div>
                </TooltipBS>
              }
            >
              <i className='bi bi-info-circle text-warning ms-2' />
            </OverlayTrigger>
          }
        </div>
      ),
    },
    {
      Header: <div className='text-end'>Total</div>,
      id: 'total_calc',
      accessor: (row) => (
        <div className='text-end'>
          {currencyFormat.format(row?.total_calc || 0)}
        </div>
      ),
    },
    {
      Header: <p className='text-center m-0'>{intl.formatMessage({ id: 'COLUMN.OUT_ORDERS.STATUS' })}</p>,
      id: 'out_order_status_id',
      className:'col-status',
      accessor: (row) =>(
        <span className={`badge ${row.label_color} w-100 justify-content-center m-0 fw-normal`}>
          {row.out_order_status_label}
        </span>
      ),
    },
    {
      Header: "",
      id: 'icons',
      accessor: (row) => (
        <div className='d-flex'>
          {/* {row.has_refrigeration==true ? <Tooltip text='Requiere frío'><span className='bi bi-snow text-primary fs-3 me-2' /></Tooltip> : <></>}
          {row.has_traced==true ? <Tooltip text='Artículos trazables'><span className='bi bi-broadcast-pin text-primary fs-3 me-2' /></Tooltip> : <></>} */}
          {row.returned_date ? <Tooltip text='Tiene redespacho'><span className='bi bi-arrow-repeat text-primary fs-3 me-2' /></Tooltip> : <></>}
        </div>
      )
    },
    {
      Header: "",
      id: 'has_regrigeration',
      accessor: (row) => (
        <>
          {Boolean(row.has_refrigeration)
            ? <Tooltip text='Requiere frío'><span><i className='bi bi-snow text-primary fs-3'></i></span></Tooltip>
            : <span></span>
          }
        </>
      )
    }
  ]

  return {
    COLUMNS
  }
}